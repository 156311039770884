import { useProjectId, useUpdateBlobMutation } from '@consigli/hooks';
import { Blob, NS3451 } from '@consigli/types';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  Modifier,
  MouseSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { getEventCoordinates } from '@dnd-kit/utilities';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useFileTreeContext } from '@/contexts/use-file-tree-context';
import { FoldersContent } from '@/pages/folders/content/folders-content';
import { FoldersSidebar } from '@/pages/folders/sidebar/folders-sidebar';
import { Tree, getNonLeafNodes } from '@/pages/folders/util/document-list-to-tree';
import { FolderDocumentsType } from '@/util/types';

import { ConfirmDragAndDropPopup } from './content/confirm-drag-popup';

export const snapCenterToCursor: Modifier = ({ activatorEvent, draggingNodeRect, transform }) => {
  if (draggingNodeRect && activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent);

    if (!activatorCoordinates) {
      return transform;
    }

    const offsetX = activatorCoordinates.x - draggingNodeRect.left;
    const offsetY = activatorCoordinates.y - draggingNodeRect.top;

    return {
      ...transform,
      x: transform.x + offsetX - draggingNodeRect.width / 30,
      y: transform.y + offsetY - draggingNodeRect.height / 2,
    };
  }

  return transform;
};

export const FoldersContainer = () => {
  const { t } = useTranslation();
  const { activeFolderFiles, tree, activeFolderId } = useFileTreeContext();
  const projectId = useProjectId();
  const [updateBlob] = useUpdateBlobMutation();
  const folders = getNonLeafNodes(tree as Tree);
  const [draggingCardId, setDraggingCardId] = useState<UniqueIdentifier>('');
  const [selectedFiles, setSelectedFiles] = useState<FolderDocumentsType[]>([]);
  const [confirmationPopupIsOpen, setConfirmationPopupIsOpen] = useState(false);
  const [activeDraggedFile, setActiveDraggedFile] = useState<Blob | null>(null);
  // same name for local and usestate variable as we are saving the same folder name where a file is being dragged
  const [selectedFolderForDrag, setSelectedFolderForDrag] = useState<UniqueIdentifier>('');
  const activeFolder = useMemo(
    () => folders.find((folder) => folder.id === activeFolderId) ?? null,
    [folders, activeFolderId],
  );

  const onDragStart = useCallback(
    (event: DragStartEvent | null) => {
      if (!event) {
        setDraggingCardId('');
        return;
      }
      const filterValues = selectedFiles.map((item: FolderDocumentsType) => item.id);
      setSelectedFiles((selected) =>
        filterValues.includes(String(event.active.id)) ? selected : [],
      );
      setDraggingCardId(event.active.id);
    },
    [selectedFiles],
  );
  const generateToastMessage = useCallback(
    (
      operation: string,
      isMultiple: boolean,
      translatedFolderName: string,
      filesToUpdate: Omit<FolderDocumentsType, 'checked'>[],
    ) => {
      const operationKey = `folders.${operation === 'copy' ? (isMultiple ? 'copying-files' : 'copying-file') : isMultiple ? 'moving-files' : 'moving-file'}`;
      const resultKey = `folders.${operation === 'copy' ? (isMultiple ? 'files-copied' : 'file-copied') : isMultiple ? 'files-moved' : 'file-moved'}`;

      return (
        <div>
          <p>
            {t(operationKey, {
              folder: translatedFolderName,
              name: isMultiple ? '' : filesToUpdate[0].name,
            })}
          </p>
          <p>
            {t(resultKey, {
              isMultiple,
              folder: translatedFolderName,
              filename: '',
            })}
          </p>
        </div>
      );
    },
    [t],
  );

  const handleUpdateBlobs = useCallback(
    async (
      operation: 'copy' | 'move',
      selectedFolderForDrag: UniqueIdentifier,
      currentDraggedFile?: Blob,
    ) => {
      const isMultiple = selectedFiles
        .map((item: FolderDocumentsType) => item.id)
        .includes(String(draggingCardId));
      const folderName = `ns3451.${selectedFolderForDrag}`;
      const translatedFolderName = t(folderName);
      let data = {
        ns3451:
          selectedFolderForDrag === '__uncategorized__'
            ? []
            : [NS3451[selectedFolderForDrag as keyof typeof NS3451]],
      };
      const filesToUpdate = isMultiple
        ? selectedFiles
        : currentDraggedFile
          ? [currentDraggedFile]
          : activeDraggedFile
            ? [activeDraggedFile]
            : [];
      const updatePromises = filesToUpdate.map((file) => {
        if (selectedFolderForDrag !== '__uncategorized__') {
          // Determine the ns3451 values to add and remove
          const ns3451ToAdd = data.ns3451.filter((value) => !file.ns3451.includes(value));
          let ns3451ToRemove: NS3451[] = [];
          let updatedNS3451: NS3451[] = [];
          if (operation === 'move') {
            ns3451ToRemove = activeFolder ? [activeFolder.id as NS3451] : [];
            updatedNS3451 = ns3451ToAdd;
          } else {
            updatedNS3451 = file.ns3451
              .filter((value) => !ns3451ToRemove.includes(value))
              .concat(ns3451ToAdd);
          }
          data = { ns3451: updatedNS3451 };
        }
        return updateBlob({
          projectId,
          packageId: filesToUpdate[0].documentPackage,
          blobId: file.id,
          data,
        }).unwrap();
      });
      try {
        await Promise.all(updatePromises);
        onDragStart(null);
        toast.success(
          generateToastMessage(operation, isMultiple, translatedFolderName, filesToUpdate),
        );
      } catch (e) {
        toast.error(t('folders.could-not-move-file'));
      } finally {
        setConfirmationPopupIsOpen(false);
      }
    },
    [
      activeFolder,
      draggingCardId,
      activeDraggedFile,
      onDragStart,
      projectId,
      selectedFiles,
      t,
      updateBlob,
      generateToastMessage,
    ],
  );

  const onDragEnd = async (event: DragEndEvent) => {
    if (event.over === null) {
      setDraggingCardId('');
      return;
    }
    const currentDraggedFile = activeFolderFiles.find((x) => x.id === event.active.id);
    const selectedFolderForDrag = event.over.id;
    if (!currentDraggedFile) {
      throw new Error(
        `Attempted to complete drop with file ${event.active.id} which does not exist in context state`,
      );
    }
    setActiveDraggedFile(currentDraggedFile);
    setSelectedFolderForDrag(selectedFolderForDrag);
    if (selectedFolderForDrag === '__uncategorized__') {
      await handleUpdateBlobs('move', selectedFolderForDrag, currentDraggedFile);
    } else {
      setConfirmationPopupIsOpen(true);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor),
    useSensor(PointerSensor),
  );

  return (
    <>
      <DndContext
        sensors={sensors}
        modifiers={[snapCenterToCursor, restrictToWindowEdges]}
        onDragEnd={(event) => {
          onDragEnd(event);
        }}
        onDragStart={onDragStart}
      >
        <div className="flex">
          {confirmationPopupIsOpen && (
            <ConfirmDragAndDropPopup
              onClose={() => {
                setConfirmationPopupIsOpen(false);
                onDragStart(null);
              }}
              onMove={() => handleUpdateBlobs('move', selectedFolderForDrag)}
              onCopy={() => handleUpdateBlobs('copy', selectedFolderForDrag)}
            />
          )}
          <FoldersSidebar />
          <FoldersContent
            draggingCardId={draggingCardId}
            activeFolder={activeFolder}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </div>
      </DndContext>
      <div id="drag-card"></div>
    </>
  );
};
