import { CustomSelect, OptionType, Search } from '@consigli/facade';
import {
  useDebouncedSearch,
  useGetBlobNameListQuery,
  useGetBlobUniqueDocumentTypesQuery,
  usePackageId,
  useProjectId,
} from '@consigli/hooks';
import { FoldersDocumentType } from '@consigli/types';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';

import { useFileTreeContext } from '@/contexts/use-file-tree-context';

export const DocumentFilters: FC = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();

  const {
    activeFolderId,
    selectedDocumentTypes,
    setSelectedDocumentTypes,
    searchText,
    setSearchText,
    setPage,
    selectedBlobIds,
    setSelectedBlobIds,
  } = useFileTreeContext();
  const debouncedSearchText = useDebouncedSearch(searchText, 500);

  const { data: documentTypes } = useGetBlobUniqueDocumentTypesQuery({
    projectId,
    packageId,
    searchParsedData: debouncedSearchText.length >= 3 ? debouncedSearchText : '',
    standard: activeFolderId,
    documentType: selectedDocumentTypes.map((option) => option.value as FoldersDocumentType),
    blobIds: selectedBlobIds.map((option) => option.value as string),
  });

  const { data: blobNameList } = useGetBlobNameListQuery({
    projectId,
    packageId,
    searchParsedData: debouncedSearchText,
    standard: activeFolderId,
    documentType: selectedDocumentTypes.map((option) => option.value as FoldersDocumentType),
  });

  const documentTypeOptions =
    documentTypes?.map((documentType) => ({
      label: t('folders.document-type.' + documentType),
      value: documentType,
    })) || [];

  const blobNameOptions: OptionType[] = useMemo(() => {
    return (
      blobNameList?.map((spec) => ({
        label: spec.name,
        value: spec.id,
      })) || []
    );
  }, [blobNameList]);

  const handleDocumentTypeSelect = useCallback(
    (options: SingleValue<OptionType> | MultiValue<OptionType>): void => {
      if (options) {
        const selectedValues = Array.isArray(options) ? options : [options];
        setSelectedDocumentTypes(selectedValues);
      } else {
        setSelectedDocumentTypes([]);
      }
      setPage(1);
    },
    [setSelectedDocumentTypes, setPage],
  );

  const handleFilterBlobNamesChange = useCallback(
    (options: SingleValue<OptionType> | MultiValue<OptionType>) => {
      if (options) {
        const selectedValues = Array.isArray(options) ? options : [options];
        setSelectedBlobIds(selectedValues);
      } else {
        setSelectedBlobIds([]);
      }
      setPage(1);
    },
    [setPage, setSelectedBlobIds],
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
      setPage(1);
    },
    [setSearchText, setPage],
  );

  return (
    <div className="flex flex-1 gap-x-2">
      <div className="flex-1">
        <CustomSelect
          options={documentTypeOptions}
          onChange={handleDocumentTypeSelect}
          value={selectedDocumentTypes}
          placeholder={t('folders.filter-on-document-type')}
          onBlur={() => {}}
          isClearable
          isMulti
        />
      </div>
      <div className="flex-1">
        <CustomSelect
          onBlur={() => {}}
          onChange={(newValue: SingleValue<OptionType> | MultiValue<OptionType>): void => {
            handleFilterBlobNamesChange(newValue);
          }}
          isMulti
          placeholder={t('riskassessment.search-document-names')}
          options={blobNameOptions}
          value={selectedBlobIds}
        />
      </div>
      <div className="flex-1">
        <Search
          text={searchText}
          onChange={handleSearch}
          placeholder={t('riskassessment.search_content')}
          className="w-full"
        />
      </div>
    </div>
  );
};
