import { Checkbox, DEFAULT_PAGE_SIZE, LoadingArea, Pagination, Position } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useProject,
  useDebouncedSearch,
  useBlobs,
  useDownloadFilesAsZip,
} from '@consigli/hooks';
import { BlobParsedStatus, FoldersDocumentType } from '@consigli/types';
import { UniqueIdentifier } from '@dnd-kit/core';
import { Dispatch, SetStateAction, useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegFolder } from 'react-icons/fa';

import { MultipleSelectionDialog } from '@/atoms/multiple-selection-dialog';
import { ServiceHeader } from '@/atoms/service-header';
import { useFileTreeContext } from '@/contexts/use-file-tree-context';
import { ViewerMode, useViewerContext } from '@/contexts/use-viewer-context';
import { DocumentsPopup } from '@/organisms/document-list/documents-popup';
import { DownloadSelected } from '@/organisms/document-list/download-selected';
import { DocumentPreviewContainer } from '@/organisms/document-viewer/document-preview';
import { DocumentFullscreenContainer } from '@/organisms/document-viewer/fullscreen/document/container';
import { TreeFolder, createTreeFile } from '@/pages/folders/util/document-list-to-tree';
import { convertFolderDocumentsTypeToDocumentsType } from '@/util/convert-folder-documents-type-to-documents-type';
import { FolderDocumentsType } from '@/util/types';

import { CreatePortal } from './create-portal';
import { DocumentFilters } from './document-filters';
import { EditOperationsFilesButton } from './edit-files-button';
import { FoldersCardList } from './folders-cardlist';

interface DraggableContainerProps {
  activeFolder: TreeFolder | null;
  draggingCardId: UniqueIdentifier;
  selectedFiles: FolderDocumentsType[] | [];
  setSelectedFiles: Dispatch<SetStateAction<FolderDocumentsType[] | []>>;
}
export const DraggableContainer = ({
  activeFolder,
  draggingCardId,
  selectedFiles,
  setSelectedFiles,
}: DraggableContainerProps) => {
  const { t } = useTranslation();
  const { mode } = useViewerContext();

  const projectId = useProjectId();
  const packageId = usePackageId();
  const { project } = useProject(projectId);

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const status: BlobParsedStatus[] = [BlobParsedStatus.PARSED, BlobParsedStatus.UNPARSED];

  const {
    activeFolderFiles,
    summaryCount,
    activeFolderId,
    page,
    setPage,
    setActiveFolderFiles,
    selectedDocumentTypes,
    selectedBlobIds,
    searchText,
  } = useFileTreeContext();
  const debouncedSearchText = useDebouncedSearch(searchText, 500);

  const { blobs, paginatedCount, isFetching } = useBlobs({
    projectId,
    packageId,
    page,
    searchParsedData: debouncedSearchText.length >= 3 ? debouncedSearchText : '',
    documentType: selectedDocumentTypes.map((option) => option.value as FoldersDocumentType),
    standard: activeFolderId,
    blobIds: selectedBlobIds.map((option) => option.value as string),
    status,
  });

  const entries = useMemo(
    () => activeFolderFiles.map((file) => createTreeFile(file)),
    [activeFolderFiles],
  );

  const files = useMemo(
    () =>
      entries?.map((x) => {
        return { ...x.blob, checked: false };
      }),
    [entries],
  );

  const [pageRecords, setPageRecords] = useState<FolderDocumentsType[]>(files);

  const draggingfile = pageRecords.find((x) => x.id === draggingCardId);

  const mappedSelectedFiles = useMemo(() => {
    return selectedFiles.map((blob: FolderDocumentsType) =>
      convertFolderDocumentsTypeToDocumentsType(blob),
    );
  }, [selectedFiles]);

  const handleSelectAllCheckboxClick = () => {
    setSelectAllChecked(!selectAllChecked);
    setPageRecords(
      pageRecords.map(
        (item: FolderDocumentsType) => item && { ...item, checked: !selectAllChecked },
      ),
    );
  };

  const {
    downloadFileZip: downloadSelected,
    isDownloading: isDownloadingSelected,
    abortDownload,
  } = useDownloadFilesAsZip(project?.name ?? 'files');

  const resetSelectAll = useCallback(() => {
    setSelectAllChecked(false);
    setPageRecords((prevData: FolderDocumentsType[]) =>
      prevData.map(
        (item: FolderDocumentsType) =>
          item && { ...item, checked: item.checked ? false : item.checked },
      ),
    );
    setShowDownloadPopup(false);
  }, []);

  const handleCheckboxClick = (record: FolderDocumentsType) => {
    setPageRecords((prevData: FolderDocumentsType[]) =>
      prevData?.map((item: FolderDocumentsType) =>
        item.id === record.id ? { ...item, checked: !item.checked } : item,
      ),
    );
  };

  const onClickDownloadSelected = async () => {
    await downloadSelected(mappedSelectedFiles);
    resetSelectAll();
    setShowDownloadPopup(false);
  };

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage],
  );

  useEffect(() => {
    setActiveFolderFiles(blobs);
  }, [blobs, setActiveFolderFiles]);

  useEffect(() => {
    setSelectedFiles(pageRecords?.filter((file: FolderDocumentsType) => file.checked));
    if (selectedFiles?.length > 1) setShowDownloadPopup(true);
  }, [selectedFiles?.length, pageRecords, setSelectedFiles]);

  useEffect(() => {
    setPageRecords(files);
  }, [files, activeFolder?.children, t]);

  const filterItems = useCallback(
    (items: FolderDocumentsType[]) => {
      const filterValues = selectedFiles.map((item: FolderDocumentsType) => item.id);
      if (!draggingCardId || !filterValues.includes(String(draggingCardId))) {
        return items;
      }
      return items.filter((item) => item.id === draggingCardId || !filterValues.includes(item.id));
    },
    [draggingCardId, selectedFiles],
  );

  const handleClosePopup = useCallback(() => {
    resetSelectAll();
    abortDownload();
  }, [resetSelectAll, abortDownload]);

  return (
    <>
      <div className="p-0 lg:px-8 flex-1 bg-day-light-3">
        <div className="px-4 pt-6 relative">
          <div className="flex items-center justify-between">
            <ServiceHeader
              icon={FaRegFolder}
              count={(summaryCount && summaryCount[activeFolderId]) || paginatedCount}
              message={t('document-list.documents')}
              title={activeFolder?.toDisplayString(t) || t('folders.all-documents')}
            />
          </div>
          <div className="mr-4 pt-4 pb-2 flex w-full">
            <Checkbox
              checked={selectAllChecked}
              label={t('riskassessment.select-all')}
              value="Select all"
              id="foldersSelectAll"
              labelPosition={Position.RIGHT}
              onChange={handleSelectAllCheckboxClick}
            />
            <DocumentFilters />
          </div>
        </div>
        {isFetching ? (
          <LoadingArea />
        ) : (
          <FoldersCardList
            filterItems={filterItems}
            handleCheckboxClick={handleCheckboxClick}
            pageRecords={pageRecords}
          />
        )}
        <div className="py-2 flex flex-col md:flex-row justify-center items-center gap-8 text-day-neutral-dark dark:text-night-neutral-dark">
          <div className="text-sm font-semibold">
            {t('riskassessment.showing_findings', { count: files.length || 0 })}
          </div>
          <Pagination
            currentPage={page}
            totalCount={paginatedCount}
            pageSize={DEFAULT_PAGE_SIZE}
            onPageChange={handlePageChange}
          />
        </div>
        <MultipleSelectionDialog>
          {showDownloadPopup && selectedFiles?.length > 1 && (
            <DocumentsPopup
              files={mappedSelectedFiles}
              setShowPopup={setShowDownloadPopup}
              onClose={handleClosePopup}
            >
              <EditOperationsFilesButton
                documents={selectedFiles}
                setShowDownloadPopup={setShowDownloadPopup}
              />
              <DownloadSelected
                onClickDownloadSelected={onClickDownloadSelected}
                isLoading={isDownloadingSelected}
              />
            </DocumentsPopup>
          )}
        </MultipleSelectionDialog>
        <CreatePortal
          draggingCardId={draggingCardId}
          draggingfile={draggingfile}
          handleCheckboxClick={handleCheckboxClick}
          selectedFiles={selectedFiles}
        />
      </div>
      {mode === ViewerMode.DocumentPreview && <DocumentPreviewContainer />}
      {mode === ViewerMode.DocumentFullscreen && <DocumentFullscreenContainer />}
    </>
  );
};
