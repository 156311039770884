import { LoadingArea, OptionType } from '@consigli/facade';
import { useProjectId, usePackageId, useBlobsNSCount, useQuery } from '@consigli/hooks';
import { Blob, FoldersDocumentType } from '@consigli/types';
import { useMemo, type FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/components/layouts/workspace-layout/workspace-layout';
import { FileTreeContext } from '@/contexts/use-file-tree-context';
import { FoldersContainer } from '@/pages/folders/folders-container';
import { createTree } from '@/pages/folders/util/document-list-to-tree';

export const FoldersPage: FC = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const queryParams = useQuery();
  const querySearchTerm = queryParams.get('q');
  const [activeFolderId, setActiveFolderId] = useState('');
  const [activeFolderFiles, setActiveFolderFiles] = useState<Blob[]>([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<OptionType[]>([]);
  const [selectedBlobIds, setSelectedBlobIds] = useState<OptionType[]>([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState(querySearchTerm || '');
  const [tree, setTree] = useState(useMemo(() => createTree(), []));
  const [selectedCard, setSelectedCard] = useState('');
  const { data, isLoading } = useBlobsNSCount({
    packageId,
    projectId,
    documentType: selectedDocumentTypes.map((option) => option.value as FoldersDocumentType),
    searchParsedData: searchText,
    blobIds: selectedBlobIds.map((option) => option.value as string),
  });
  const [summaryCount, setSummaryCount] = useState(data);

  useEffect(() => {
    setSummaryCount(data);
  }, [data]);
  return (
    <WorkspaceLayout>
      <Breadcrumb label={t('servicetabs.folders')} />
      {isLoading ? (
        <LoadingArea title={t('folders.loading-folders')} />
      ) : (
        <FileTreeContext.Provider
          value={{
            activeFolderFiles,
            setActiveFolderFiles,
            activeFolderId,
            setActiveFolderId,
            tree,
            setTree,
            summaryCount,
            page,
            setPage,
            setSelectedDocumentTypes,
            selectedDocumentTypes,
            selectedBlobIds,
            setSelectedBlobIds,
            searchText,
            setSearchText,
            selectedCard,
            setSelectedCard,
          }}
        >
          <FoldersContainer />
        </FileTreeContext.Provider>
      )}
    </WorkspaceLayout>
  );
};
